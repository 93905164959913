export const WorkflowConstant = {
    CREATE_NEW_WORKFLOW_REQUEST: 'CREATE_NEW_WORKFLOW_REQUEST',
    CREATE_NEW_WORKFLOW_SUCCESS: 'CREATE_NEW_WORKFLOW_SUCCESS',
    CREATE_NEW_WORKFLOW_FAILURE: 'CREATE_NEW_WORKFLOW_FAILURE',

    GET_WORKFLOW_DETAIL_REQUEST: 'GET_WORKFLOW_DETAIL_REQUEST',
    GET_WORKFLOW_DETAIL_SUCCESS: 'GET_WORKFLOW_DETAIL_SUCCESS',
    GET_WORKFLOW_DETAIL_FAILURE: 'GET_WORKFLOW_DETAIL_FAILURE',

    GET_WORKFLOW_ALL_DETAIL_REQUEST: 'GET_WORKFLOW_ALL_DETAIL_REQUEST',
    GET_WORKFLOW_ALL_DETAIL_SUCCESS: 'GET_WORKFLOW_ALL_DETAIL_SUCCESS',
    GET_WORKFLOW_ALL_DETAIL_FAILURE: 'GET_WORKFLOW_ALL_DETAIL_FAILURE',

    UPDATE_WORKFLOW_STEP_REQUEST: 'UPDATE_WORKFLOW_STEP_REQUEST',
    UPDATE_WORKFLOW_STEP_SUCCESS: 'UPDATE_WORKFLOW_STEP_SUCCESS',
    UPDATE_WORKFLOW_STEP_FAILURE: 'UPDATE_WORKFLOW_STEP_FAILURE',

    UPDATE_WORKFLOW_STAGE_REQUEST: 'UPDATE_WORKFLOW_STAGE_REQUEST',
    UPDATE_WORKFLOW_STAGE_SUCCESS: 'UPDATE_WORKFLOW_STAGE_SUCCESS',
    UPDATE_WORKFLOW_STAGE_FAILURE: 'UPDATE_WORKFLOW_STAGE_FAILURE',

    GET_WORKFLOW_EDIT_DETAIL_REQUEST: 'GET_WORKFLOW_EDIT_DETAIL_REQUEST',
    GET_WORKFLOW_EDIT_DETAIL_SUCCESS: 'GET_WORKFLOW_EDIT_DETAIL_SUCCESS',
    GET_WORKFLOW_EDIT_DETAIL_FAILURE: 'GET_WORKFLOW_EDIT_DETAIL_FAILURE',

    EDIT_WORKFLOW_DETAIL_REQUEST: 'EDIT_WORKFLOW_DETAIL_REQUEST',
    EDIT_WORKFLOW_DETAIL_SUCCESS: 'EDIT_WORKFLOW_DETAIL_SUCCESS',
    EDIT_WORKFLOW_DETAIL_FAILURE: 'EDIT_WORKFLOW_DETAIL_FAILURE',

    UPDATE_STAGE_CHECKLIST_REQUEST: 'UPDATE_STAGE_CHECKLIST_REQUEST',
    UPDATE_STAGE_CHECKLIST_SUCCESS: 'UPDATE_STAGE_CHECKLIST_SUCCESS',
    UPDATE_STAGE_CHECKLIST_FAILURE: 'UPDATE_STAGE_CHECKLIST_FAILURE',
};